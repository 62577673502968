import React from 'react'
import imagen3 from "../assets/imagenCelular3.png";
import imagen7 from "../assets/imagenCelular7.png";
import imagen8 from "../assets/imagenCelular8.png";

export default function Section3() {
    return (
        <>
        <div className="min-h-screen items-center container mx-auto">
            <div className="flex flex-wrap justify-center mt-10 mx-10 md:mx-8">
                    <div className=" lg:w-2/5 bg-purple-500 px-10 py-16 md:px-16 md:py-24 lg:px-10 xl:px-20 lg:py-28 2xl:py-36 2xl:px-24 rounded-t-xl lg:rounded-l-xl lg:rounded-tr-none">
                        <h1 className=" text-black font-extrabold text-3xl sm:text-4xl lg:text-5xl">Notificaciones y Estados de Cuenta</h1>
                        <p className="text-black text-base md:text-lg lg:text-xl mt-3"> Recibe en tu celular, notificaciones relacionadas a tu contrato favorito, asi como alertas por el mantenimiento en las redes de agua que te afecten. Descarga tus estados de cuenta siempre que los necesites</p>
                    </div>
                    <img src={imagen7} className="lg:w-3/6 rounded-b-xl lg:rounded-r-xl lg:rounded-bl-none" alt="" />

            </div>
            <div className="flex flex-wrap justify-center mt-12">
                <div className="lg:w-2/5 mx-10 my-3 lg:mx-2 lg:my-0">
                    <div className="bg-gray-400 bg-opacity-10 text-center rounded-tl-2xl rounded-tr-2xl">
                        <img src={imagen8} className="" alt="" />
                    </div>
                    <div className=" bg-gray-400 bg-opacity-20 rounded-bl-2xl rounded-br-2xl">
                        <h1 className=" text-purple-500 text-center font-bold text-lg sm:text-2xl py-5">Notificaciones <br/> Recibidas</h1>
                    </div>
                </div>
                <div className="lg:w-2/5 mx-10 my-3 lg:mx-2 lg:my-0">
                    <div className="bg-gray-400 bg-opacity-10 text-center rounded-tl-2xl rounded-tr-2xl">
                        <img src={imagen3} className="" alt="" />
                    </div>
                    <div className=" bg-gray-400 bg-opacity-20 rounded-bl-2xl rounded-br-2xl">
                        <h1 className=" text-purple-500 text-center font-bold text-lg sm:text-2xl py-5"> Descarga tu Estado<br/> de Cuenta</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto h-2 bg-gray-300 bg-opacity-40 w-32 my-20 rounded-3xl"></div>
        </div>
        </>
    )
}
