import React from 'react'
import logo from '../assets/phone1.png';
import playstore from "../assets/play.png";


export default function Header() {
    return (
        <>
            <div className="items-center min-h-screen flex justify-center container mx-auto">
                <div className="invisible w-0 lg:visible lg:w-1/2">
                    <img className="" src={logo} alt="" />
                </div>
                <div className="w-full md:w-full lg:w-2/3 text-left m-8 lg:m-28">
                    <div className="flex">
                        <div className=" bg-blue-400 rounded-3xl bg-opacity-40  h-16 w-16  sm:h-20 sm:w-20">
                            <img className=" h-16 sm:h-20 p-2" src="https://www.ciapacov.gob.mx//assets/img/logoSolo.png" alt="" />
                        </div>
                        <p className=" ml-3 mt-5 sm:mt-7 text-base sm:text-xl font-bold text-gray-400">CIAPACOV Móvil</p>
                    </div>
                    <h1 className="my-5 text-3xl sm:text-4xl md:text-5xl font-extrabold text-white">La aplicación para tomar el control de tu agua</h1>
                    <p className="text-gray-50 font-semibold text-normal sm:text-lg md:text-xl mt-5 mb-2">La nueva forma de saber lo que pasa con tus contratos al alcance de tus manos y que siempre va contigo</p>
                    <a href="https://play.google.com/store/apps/details?id=com.ciapacov.ciapacovmovil&hl=es_MX"><img className="w-44" src={playstore} alt="" /></a>
                </div>

                
            </div>
        </>
    )
}
