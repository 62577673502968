import React from 'react';
import { CheckCircle,Info } from 'react-feather';
import imagen1 from "../assets/imagenCelular1.png";
import imagen2 from "../assets/imagenCelular2.png";
import imagen2_5 from "../assets/imagenCelular2-5.png";

export default function Section1() {
    return (
        <>
        <div className="min-h-screen items-center container mx-auto">
            <div className="flex flex-wrap justify-center">
                <div className=" bg-gray-400 bg-opacity-10 text-center rounded-xl w-full my-3 mx-7 md:w-2/5 md:mr-2">
                    <h1 className=" text-white p-5 font-medium sm:font-semibold flex justify-center"> <Info color="white" className="mr-2"/> Información oficial y al momento </h1>
                </div>
                <div className=" bg-gray-400 bg-opacity-10 text-center rounded-xl w-full my-3 mx-7 md:w-2/5 md:ml-2">
                    <h1 className=" text-white p-5 font-medium sm:font-semibold flex justify-center"> <CheckCircle color="white" className="mr-2"/> Cuenta 100% segura y confiable</h1>
                </div>
            </div>
            <div className="container mx-auto h-2 bg-gray-300 bg-opacity-40 w-32 my-20 rounded-3xl"></div>

            <div className="flex flex-wrap justify-center mt-10 mx-10 md:mx-8">
                    <div className="lg:w-2/5 bg-blue-500 px-10 py-16 md:px-16 md:py-24 lg:px-10 xl:px-20 lg:py-28 2xl:py-36 2xl:px-24 rounded-t-xl lg:rounded-l-xl lg:rounded-tr-none">
                        <h1 className="text-black font-extrabold text-3xl sm:text-4xl lg:text-5xl">Saldos y Pagos en línea</h1>
                        <p className=" text-black text-base md:text-lg lg:text-xl mt-3">Podrás conocer cuantos metros cúbicos consumiste, conocer el saldo de tu contrato en el mes y  pagarlo al instante. </p>
                    </div>
                    <img src={imagen1} className="lg:w-3/6 rounded-b-xl lg:rounded-r-xl lg:rounded-bl-none" alt="" />
            </div>
            <div className="flex flex-wrap justify-center mt-12">
                <div className="lg:w-2/5 mx-10 my-3 lg:mx-2 lg:my-0">
                    <div className="bg-gray-400 bg-opacity-10 text-center rounded-tl-2xl rounded-tr-2xl">
                        <img src={imagen2} className="" alt="" />
                    </div>
                    <div className=" bg-gray-400 bg-opacity-20 rounded-bl-2xl rounded-br-2xl">
                        <h1 className=" text-blue-500 text-center font-bold text-lg sm:text-2xl py-5">Historial de <br/> Pagos </h1>
                    </div>
                </div>
                <div className="lg:w-2/5 mx-10 my-3 lg:mx-2 lg:my-0">
                    <div className="bg-gray-400 bg-opacity-10 text-center rounded-tl-2xl rounded-tr-2xl">
                        <img src={imagen2_5} className="" alt="" />
                    </div>
                    <div className=" bg-gray-400 bg-opacity-20 rounded-bl-2xl rounded-br-2xl">
                        <h1 className=" text-blue-500 text-center font-bold text-lg sm:text-2xl py-5"> Detalles de Pagos<br/>  Mensuales</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto h-2 bg-gray-300 bg-opacity-40 w-32 my-20 rounded-3xl"></div>
        </div>
        </>
    )
}
