import React from 'react';
import ReactDOM from 'react-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Section4 from './components/Section4';

ReactDOM.render(
  <React.StrictMode>
    <Header/>
    <Section1/>
    <Section4/>
    <Section2/>
    <Section3/> 
    <Footer/> 
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
