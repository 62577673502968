import React from 'react'
import playstore from "../assets/play.png";


export default function Footer() {
    return (
        <div className="container mx-auto w-10/12 md:w-9/12">
            <h1 className="text-white font-medium text-3xl md:text-4xl mb-10">Preguntas Frecuentes</h1>
            <div className="bg-gray-400 bg-opacity-10 py-8 px-8 rounded-2xl mb-3">
                <h2 className=" text-white font-bold text-xl md:text-2xl mb-3">¿ Cuando estará disponible la aplicación para dispositivos de Apple ?</h2>
                <p className="text-white font-light text-base md:text-lg"> La aplicación está en la fase de aceptación por la App Store de Apple y proximamente estará disponible para los celulares con sistema operativo IOS.</p>
            </div>
            <div className="bg-gray-400 bg-opacity-10 py-8 px-8 rounded-2xl mb-3">
                <h2 className=" text-white font-bold text-xl md:text-2xl mb-3">¿ Que pasa si mi contrato ya se encuentra enlazado a otra cuenta ?</h2>
                <p className="text-white font-light text-base md:text-lg"> Acude a las oficinas administrativas de la CIAPACOV, pide comunicarte con el departamento de informática, lleva contigo documentos que validen que el contrato este a tu nombre (INE y Predial) y con gusto vincularemos el contrato a tu cuenta.</p>
            </div>
            <div className="bg-gray-400 bg-opacity-10 py-8 px-8 rounded-2xl mb-3">
                <h2 className=" text-white font-bold text-xl md:text-2xl mb-3">¿ Puedo acceder con la misma cuenta a la aplicación "CIAPACOV Móvil" y al portal "Mi Cuenta" de la CIAPACOV ?</h2>
                <p className="text-white font-light text-base md:text-lg"> Efectivamente, tu cuenta te da acceso a todos los servicios y plataformas de la CIAPACOV que estén disponibles.</p>
            </div>
            <div className="bg-gray-400 bg-opacity-10 py-8 px-8 rounded-2xl mb-3">
                <h2 className=" text-white font-bold text-xl md:text-2xl mb-3">¿ Que otro beneficio tendrá descargar la aplicación de CIAPACOV ?</h2>
                <p className="text-white font-light text-base md:text-lg"> Podrás estar al pendiente siempre de tus contratos y los adeudos de los mismos en cada bimestre, se podrá facturar los recibos y generár CFDI. También puedes ubicar rapidamente todas las oficinas de la CIAPACOV.</p>
            </div>
            <div className="bg-gray-400 bg-opacity-10 py-8 px-8 rounded-2xl mb-32">
                <h2 className=" text-white font-bold text-xl md:text-2xl mb-3">¿ La app de CIAPACOV es gratis ?</h2>
                <p className="text-white font-light text-base md:text-lg"> ¡Porsupuesto que si!, la aplicación es completamente gratis para que la puedas llevar a todas partes.</p>
            </div>

            <div className="bg-gray-400 bg-opacity-40 py-3 lg:py-4 px-6 lg:px-8 rounded-2xl flex flex-wrap justify-center text-center lg:justify-between">
                <h2 className=" text-white font-medium text-2xl lg:text-3xl pt-5">Obtén CIAPACOV Móvil ahora mismo</h2>
                <a href="https://play.google.com/store/apps/details?id=com.ciapacov.ciapacovmovil&hl=es_MX"><img className="w-48" src={playstore} alt="" /></a>
            </div>
            <div className="my-10 flex flex-wrap text-center justify-center lg:justify-between">
                <h1 className="text-white font-extrabold"> Desarrollado por CIAPACOV Copyright ©2021</h1>
                <h1 className="text-white font-medium"><a href="https://ciapacov.gob.mx">Visita la Página Oficial</a> </h1>
                <h1 className="text-white font-medium"><a href="mailto:informatica@ciapacov.gob.mx?subject=Desde%20CIAPACOV%20Movil%20Oficial"> informatica@ciapacov.gob.mx</a></h1>
            </div>

        </div>
    )
}
