import React from 'react'
import imagen102 from "../assets/imagenCelular102.png";
import imagen100 from "../assets/imagenCelular100.png";
import imagen104 from "../assets/imagenCelular101.png";

export default function Section4() {
    return (
        <>
        <div className="min-h-screen items-center container mx-auto">
            <div className="flex flex-wrap justify-center mt-10 mx-10 md:mx-8">
                    <div className="lg:w-2/5 bg-blue-300 px-10 py-16 md:px-16 md:py-24 lg:px-10 xl:px-20 lg:py-28 2xl:py-36 2xl:px-24 rounded-t-xl lg:rounded-l-xl lg:rounded-tr-none">
                        <h1 className="text-black font-extrabold text-3xl sm:text-4xl lg:text-5xl">Vincula y Elige tu Favorito </h1>
                        <p className=" text-black text-base md:text-lg lg:text-xl mt-3">Puedes vincular varios contratos a tu cuenta. Elige un favorito para poder recibir notificaciones importantes sobre los movimientos que afecten a tu contrato.</p>
                    </div>
                    <img src={imagen100} className="lg:w-3/6 rounded-b-xl lg:rounded-r-xl lg:rounded-bl-none" alt="" />
            </div>
            <div className="flex flex-wrap justify-center mt-12">
                <div className="lg:w-2/5 mx-10 my-3 lg:mx-2 lg:my-0">
                    <div className="bg-gray-400 bg-opacity-10 text-center rounded-tl-2xl rounded-tr-2xl">
                        <img src={imagen104} className="" alt="" />
                    </div>
                    <div className=" bg-gray-400 bg-opacity-20 rounded-bl-2xl rounded-br-2xl">
                        <h1 className=" text-blue-300 text-center font-bold text-lg sm:text-2xl py-5">Vincula Varios<br/> Contratos</h1>
                    </div>
                </div>
                <div className="lg:w-2/5 mx-10 my-3 lg:mx-2 lg:my-0">
                    <div className="bg-gray-400 bg-opacity-10 text-center rounded-tl-2xl rounded-tr-2xl">
                        <img src={imagen102} className="" alt="" />
                    </div>
                    <div className=" bg-gray-400 bg-opacity-20 rounded-bl-2xl rounded-br-2xl">
                        <h1 className=" text-blue-300 text-center font-bold text-lg sm:text-2xl py-5"> Selecciona un <br/> Favorito</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto h-2 bg-gray-300 bg-opacity-40 w-32 my-20 rounded-3xl"></div>
        </div>
        </>
    )
}
